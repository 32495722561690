export default [
  {
    url: "/clinic/notice-board",
    name: "Dashboard",
    icon: "GridIcon",
    slug: "dashboard",
    i18n: "Dashboard",
  },
  {
    url: "/clinic/doctors",
    name: "Doctor Management",
    slug: "clinic-doctors-management",
    i18n: "Doctors Management",
    icon: "HeadphonesIcon",
  },
  {
    url: "/clinic/nurses",
    name: "Nurses Management",
    slug: "clinic-nurses-management",
    i18n: "Nurses Management",
    icon: "UserIcon",
  },
  {
    url: "/clinic/patients",
    name: "Patient Management",
    slug: "pages-register-nurse",
    i18n: "Patient Management",
    icon: "UsersIcon",
  },
  // {
  //   url: "/clinic/assign-clinic",
  //   name: "Assign Clinic",
  //   slug: "assign-clinic",
  //   i18n: "Assign Clinic",
  //   icon: "UsersIcon"
  // },
  {
    url: "/clinic/pair-device",
    name: "Pair Device",
    slug: "pair-device",
    i18n: "Pair Devices",
    icon: "SmartphoneIcon",
  },
  {
    url: "/clinic/custom-consent",
    name: "Consent Form",
    slug: "custom-consent",
    i18n: "Consent Form",
    icon: "SmartphoneIcon",
  },
  {
    url: "/clinic/treatment-history",
    name: "Treatment History",
    icon: "ActivityIcon",
    slug: "treatment-history",
    i18n: "Treatment History",
  },
  {
    url: "/clinic/invoice",
    name: "Invoice",
    slug: "invoice",
    i18n: "Invoice",
    icon: "DollarSignIcon",
  },
  {
    url: "/clinic/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events",
  },
  {
    url: "/clinic/resources",
    name: "Resources",
    icon: "CodepenIcon",
    slug: "resources",
    i18n: "Resources",
  },
  {
    header: "Product Management",
    icon: "LayersIcon",
    i18n: "Product Management",
    items: [
      {
        url: "/clinic/shop",
        name: "Shop",
        slug: "shop",
        i18n: "Shop",
        icon: "ShoppingBagIcon",
      },
      // {
      //   url: "/clinic/coming-soon",
      //   name: "Shop",
      //   slug: "coming-soon",
      //   i18n: "Shop",
      //   icon: "HomeIcon"
      // },
      {
        url: "/clinic/orders",
        name: "Orders",
        slug: "orders",
        i18n: "My Orders",
        icon: "TruckIcon",
      },
    ],
  },
  {
    header: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    name: "clinicSettings",
    items: [
      {
        url: "/clinic/settings/doctor-rate",
        name: "Doctor Rate",
        icon: "StarIcon",
        slug: "doctor-rate",
        i18n: "Doctor Rate"
      }
    ]
  },
  {
    header: "Product Management",
    icon: "LayersIcon",
    i18n: "Product Management",
    items: [
      {
        url: "/clinic/shop",
        name: "Shop",
        slug: "shop",
        i18n: "Shop",
        icon: "HomeIcon"
      },
      // {
      //   url: "/clinic/coming-soon",
      //   name: "Shop",
      //   slug: "coming-soon",
      //   i18n: "Shop",
      //   icon: "HomeIcon"
      // },
      {
        url: "/clinic/orders",
        name: "Orders",
        slug: "orders",
        i18n: "Orders",
        icon: "ListIcon"
      }
    ]
  }
];
